$(document).ready(function () {
  document.addEventListener("change", function (event) {
    event.preventDefault();
    let element = event.target;

    if (element && element.matches(".form-element-field")) {
      element.classList[element.value ? "add" : "remove"]("-hasvalue");
    }
  });

  window.onscroll = function () {
    myFunction();
  };

  var header = document.getElementById("header");
  var sticky = header.offsetTop;

  function myFunction() {
    if (window.pageYOffset > sticky) {
      header.classList.add("sticky");
    } else {
      header.classList.remove("sticky");
    }
  }

  document.querySelector('#menu-btn-mob').addEventListener('click', e => {
    e.preventDefault();
    var menubtn = document.querySelector('#menu-btn-mob');
    menubtn.classList.toggle('is-open');
    var overlaymenu = document.querySelector('.menu-mobile');
    overlaymenu.classList.toggle('is-open-menu');
  });
  AOS.init(); // You can also pass an optional settings object
  // below listed default settings

  AOS.init({
    // Global settings:
    disable: false,
    // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
    startEvent: 'DOMContentLoaded',
    // name of the event dispatched on the document, that AOS should initialize on
    initClassName: 'aos-init',
    // class applied after initialization
    animatedClassName: 'aos-animate',
    // class applied on animation
    useClassNames: false,
    // if true, will add content of `data-aos` as classes on scroll
    disableMutationObserver: false,
    // disables automatic mutations' detections (advanced)
    debounceDelay: 50,
    // the delay on debounce used while resizing window (advanced)
    throttleDelay: 99,
    // the delay on throttle used while scrolling the page (advanced)
    // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
    offset: 150,
    // offset (in px) from the original trigger point
    delay: 0,
    // values from 0 to 3000, with step 50ms
    duration: 400,
    // values from 0 to 3000, with step 50ms
    easing: 'ease',
    // default easing for AOS animations
    once: false,
    // whether animation should happen only once - while scrolling down
    mirror: false,
    // whether elements should animate out while scrolling past them
    anchorPlacement: 'top-bottom' // defines which position of the element regarding to window should trigger the animation

  }); //Sliders

  $('.slider-home').slick({
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    slide: '.slid',
    autoplay: true,
    autoplaySpeed: 3000,
    rows: 0 // Fix vor v1.8.0-1

  });
  $('.slider').slick({
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    slide: '.slid',
    autoplay: true,
    autoplaySpeed: 3000,
    rows: 0 // Fix vor v1.8.0-1

  });
  $('.slids-biens').slick({
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    centerMode: true,
    variableWidth: true
  });
  $('#sliver-avis').slick({
    dots: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    slide: '.slid',
    autoplay: true,
    autoplaySpeed: 6000,
    rows: 0 // Fix vor v1.8.0-1

  }); //Qui
  // function resizePage(){
  //     var Largeur = $(window).width();
  //     if(Largeur > 992) {
  //       $(window).scroll(function fix_element() {
  //         $('.zone-number').css(
  //           $(window).scrollTop() > 3650
  //             ? { 'position': 'fixed', 'top': '60px' }
  //             : { 'position': 'relative', 'top': 'auto' }
  //         );
  //         return fix_element;
  //       }());
  //       $(window).scroll(function () {
  //         //Number 2
  //         if ($(this).scrollTop() > 3820) { 
  //             $('.zone-number .numb').html('2');
  //         }else{
  //           $('.zone-number .numb').html('1');
  //         }
  //         //Number 3
  //         if ($(this).scrollTop() > 4070) { 
  //           $('.zone-number .numb').html('3');
  //         }
  //         if ($(this).scrollTop() > 4150) { 
  //           $('.zone-number').css({ 'position': 'relative', 'top': '500px' });
  //         }
  //       });
  //   }
  // }
  // $(window).resize(resizePage);
  // resizePage(); // Appel de la fonction à l'affichage de la page.

  gsap.registerPlugin(MotionPathPlugin); //Home nuage

  var nuage1 = {
    curviness: 1.25,
    path: [{
      x: -2,
      y: 0
    }]
  };

  if (document.querySelector(".nuage1-home")) {
    var nuage1anim = gsap.timeline();
    nuage1anim.to('.nuage1-home', 4, {
      motionPath: nuage1,
      ease: Power1.easeInOut
    }).to('.nuage1-home', 1, {
      x: 0,
      ease: Power2.easeOut,
      repeat: -1,
      yoyo: true
    });
  }

  var nuage2 = {
    curviness: 1.25,
    path: [{
      x: 0,
      y: 0
    }]
  };

  if (document.querySelector(".nuage2-home")) {
    var nuage2anim = gsap.timeline();
    nuage2anim.to('.nuage2-home', 5, {
      motionPath: nuage2,
      ease: Power1.easeInOut
    }).to('.nuage2-home', .8, {
      x: 2,
      ease: Power2.easeOut,
      repeat: -1,
      yoyo: true
    });
  }

  var nuage3 = {
    curviness: 1.25,
    path: [{
      x: 50,
      y: 0
    }]
  };

  if (document.querySelector(".nuage3-home")) {
    var nuage3anim = gsap.timeline();
    nuage3anim.to('.nuage3-home', 5, {
      motionPath: nuage3,
      ease: Power1.easeInOut
    }).to('.nuage3-home', .7, {
      x: 48,
      ease: Power2.easeOut,
      repeat: -1,
      yoyo: true
    });
  } //Contact


  var nuage1 = {
    curviness: 1.25,
    path: [{
      x: 65,
      y: 0
    }]
  };

  if (document.querySelector(".nuage1")) {
    var nuage1anim = gsap.timeline();
    nuage1anim.to('.nuage1', 4, {
      motionPath: nuage1,
      ease: Power1.easeInOut
    }).to('.nuage1', 1, {
      x: 68,
      ease: Power2.easeOut,
      repeat: -1,
      yoyo: true
    });
  }

  var nuage2 = {
    curviness: 1.25,
    path: [{
      x: -40,
      y: 0
    }]
  };

  if (document.querySelector(".nuage2")) {
    var nuage2anim = gsap.timeline();
    nuage2anim.to('.nuage2', 5, {
      motionPath: nuage2,
      ease: Power1.easeInOut
    }).to('.nuage2', .8, {
      x: -38,
      ease: Power2.easeOut,
      repeat: -1,
      yoyo: true
    });
  }

  var nuage3 = {
    curviness: 1.25,
    path: [{
      x: 10,
      y: 0
    }]
  };

  if (document.querySelector(".nuage3")) {
    var nuage3anim = gsap.timeline();
    nuage3anim.to('.nuage3', 2, {
      motionPath: nuage3,
      ease: Power1.easeInOut
    }).to('.nuage3', .7, {
      x: 12,
      ease: Power2.easeOut,
      repeat: -1,
      yoyo: true
    });
  }

  if (document.body.classList.contains('single-biens_en_location ')) {
    var script = document.createElement('script');
    script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBoZVI7ItgOT_Hi9edhPt0YZ_iZj19VrXQ&callback=initMap';
    script.async = true;

    window.initMap = function () {
      function initMap($el) {
        // Find marker elements within map.
        var $markers = $el.find('.marker'); // Create gerenic map.

        var mapArgs = {
          zoom: $el.data('zoom') || 16,
          mapTypeId: google.maps.MapTypeId.ROADMAP
        };
        var map = new google.maps.Map($el[0], mapArgs); // Add markers.

        map.markers = [];
        $markers.each(function () {
          initMarker($(this), map);
        }); // Center map based on markers.

        centerMap(map); // Return map instance.

        return map;
      }

      function initMarker($marker, map) {
        // Get position from marker.
        var lat = $marker.data('lat');
        var lng = $marker.data('lng');
        var latLng = {
          lat: parseFloat(lat),
          lng: parseFloat(lng)
        }; // Create marker instance.

        var marker = new google.maps.Marker({
          position: latLng,
          map: map
        }); // Append to reference for later use.

        map.markers.push(marker); // If marker contains HTML, add it to an infoWindow.

        if ($marker.html()) {
          // Create info window.
          var infowindow = new google.maps.InfoWindow({
            content: $marker.html()
          }); // Show info window when marker is clicked.

          google.maps.event.addListener(marker, 'click', function (e) {
            e.preventDefault();
            infowindow.open(map, marker);
          });
        }
      }

      function centerMap(map) {
        // Create map boundaries from all map markers.
        var bounds = new google.maps.LatLngBounds();
        map.markers.forEach(function (marker) {
          bounds.extend({
            lat: marker.position.lat(),
            lng: marker.position.lng()
          });
        }); // Case: Single marker.

        if (map.markers.length == 1) {
          map.setCenter(bounds.getCenter()); // Case: Multiple markers.
        } else {
          map.fitBounds(bounds);
        }
      } // Render maps on page load.


      $('.acf-map').each(function () {
        var map = initMap($(this));
      });
    };

    document.head.appendChild(script);
  }
});